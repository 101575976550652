const yup = require("yup");

const ValidationsSpecification = yup.object().shape({
    registration: yup.object().shape({
        vendemmiaOperationUnit: yup.array().required("Campo obrigatório").nullable(),
        operationType: yup.array().required("Campo obrigatório"),
        companyName: yup.string().required("Campo obrigatório"),
        companyFantasyName: yup.string().required("Campo obrigatório"),
        companyOperationDocumentNumber: yup.string().required("Campo obrigatório"),
        companyOperationSubscriptionState: yup.string().required("Campo obrigatório"),
        companyBranches: yup
            .array(
                yup.object({
                    companyOperationDocumentNumber: yup.string(),
                    subscriptionState: yup.string(),
                })
            )
            .test({
                message: "Os campos acima devem ser completamente preenchidos",
                test: arr => {
                    return arr.find(({ companyOperationDocumentNumber, subscriptionState }) => {
                        return companyOperationDocumentNumber && subscriptionState;
                    });
                },
            }),
    }),
    contact: yup.object().shape({
        commercial: yup
            .array(
                yup.object({
                    name: yup.string(),
                    phone: yup.string(),
                    email: yup.string(),
                })
            )
            .test({
                message: "Os campos acima devem ser completamente preenchidos",
                test: arr => {
                    return arr.find(({ name, phone, email }) => {
                        return name && phone && email;
                    });
                },
            }),
        operation: yup
            .array(
                yup.object({
                    name: yup.string(),
                    phone: yup.string(),
                    email: yup.string(),
                })
            )
            .test({
                message: "Os campos acima devem ser completamente preenchidos",
                test: arr => {
                    return arr.find(({ name, phone, email }) => {
                        return name && phone && email;
                    });
                },
            }),
        financial: yup
            .array(
                yup.object({
                    name: yup.string(),
                    phone: yup.string(),
                    email: yup.string(),
                })
            )
            .test({
                message: "Os campos acima devem ser completamente preenchidos",
                test: arr => {
                    return arr.find(({ name, phone, email }) => {
                        return name && phone && email;
                    });
                },
            }),
        legal: yup
            .array(
                yup.object({
                    name: yup.string(),
                    phone: yup.string(),
                    email: yup.string(),
                })
            )
            .test({
                message: "Os campos acima devem ser completamente preenchidos",
                test: arr => {
                    return arr.find(({ name, phone, email }) => {
                        return name && phone && email;
                    });
                },
            }),
    }),
    commercial: yup.object().shape({
        generalRestrictionsInformation: yup.string().required("Campo obrigatório"),
        profitabilityInformation: yup.string().required("Campo obrigatório"),
        contractValidUntil: yup.string().required("Campo obrigatório"),
        annualBilling: yup.string().required("Campo obrigatório"),
        additionalInformation: yup.string(),
    }),
    tributary: yup.object().shape({
        isIcmsTaxpayer: yup.string().required("Campo obrigatório"),
        operationPurpose: yup.array().required("Campo obrigatório"),
        fiscalBenefit: yup.array().required("Campo obrigatório"),
        taxRegime: yup.string().required("Campo obrigatório"),
        additionalInformation: yup.string(),
    }),
    operational: yup.object().shape({
        forwardingAgent: yup.string().required("Campo obrigatório"),
        dispatchValue: yup.string().required("Campo obrigatório"),
        productTypeDescription: yup.string().required("Campo obrigatório"),
        isAntidumping: yup.string().required("Campo obrigatório"),
        importingLicenseConsentingEntity: yup.array().required("Campo obrigatório"),
        importingLicenseValue: yup.string().required("Campo obrigatório"),
        importingLicenseAdditionalInformation: yup.string(),
        operationNature: yup.string().required("Campo obrigatório"),
        incoterm: yup.string().required("Campo obrigatório"),
        incotermAdditionalInformation: yup.string(),
        hasInternationalTransportQuote: yup.string().required("Campo obrigatório"),
        transportModality: yup.array().required("Campo obrigatório"),
        goodsEntranceFederativeUnit: yup.string().required("Campo obrigatório"),
        goodsEntranceFederativeAdditionalInformation: yup.string(),
        customsClearanceLocation: yup.array().required("Campo obrigatório"),
        customsClearanceLocationAdditionalInformation: yup.string(),
        insuranceCalculationBasisVendemmia: yup.string().required("Campo obrigatório"),
        insuranceCalculationBasisClient: yup.string().required("Campo obrigatório"),
        insuranceAdditionalInformation: yup.string(),
        storageAfterBilling: yup.string().required("Campo obrigatório"),
        storageAfterBillingAdditionalInformation: yup.string(),
        nationalFreightModality: yup.string().required("Campo obrigatório"),
        nationalFreightBilling: yup.string().required("Campo obrigatório"),
        nationalFreightAdditionalInformation: yup.string(),
        nmcRelation: yup.array(), //TODO Esse campo ainda vai existir?
    }),
    financial: yup.object().shape({
        upFrontPaymentWarranty: yup.string().required("Campo obrigatório"),
        upFrontPaymentWarrantyAdditionalInformation: yup.string(),
        commercialDiscount: yup.boolean().required("Campo obrigatório"),
        icmsRate: yup.string().when("commercialDiscount", {
            is: true,
            then: yup.string().required("Campo obrigatório"),
        }),
        discountValue: yup.string().when("commercialDiscount", {
            is: true,
            then: yup.string().required("Campo obrigatório"),
        }),
        commercialDiscountAdditionalInformation: yup.string(),
        serviceInvoice: yup.boolean().required("Campo obrigatório"),
        administrateTax: yup.string().when("serviceInvoice", {
            is: true,
            then: yup.string().required("Campo obrigatório"),
        }),
        serviceInvoiceMinValue: yup.string().when("serviceInvoice", {
            is: true,
            then: yup.string().required("Campo obrigatório"),
        }),
        serviceInvoiceModality: yup.string().when("serviceInvoice", {
            is: true,
            then: yup.string().required("Campo obrigatório"),
        }),
        serviceInvoiceAdditionalInformation: yup.string(),
        exporterPayment: yup.array().required("Campo obrigatório"),
        exporterPaymentAdditionalInformation: yup.string(),
        financialCharges: yup.boolean().required("Campo obrigatório"),
        financialChargesModality: yup.string().when("financialCharges", {
            is: true,
            then: yup.string().required("Campo obrigatório"),
        }),
        financialChargesAddition: yup.string().when("financialCharges", {
            is: true,
            then: yup.string().required("Campo obrigatório"),
        }),
        financialChargesAdditionalInformation: yup.string(),
        paymentTermsCommodity: yup.string().required("Campo obrigatório"),
        paymentTermsTax: yup.string().required("Campo obrigatório"),
        paymentTermsExpenses: yup.string().required("Campo obrigatório"),
        paymentTermsServiceInvoice: yup.string().required("Campo obrigatório"),
        paymentTermsAdditionalInformation: yup.string(),
    }),
});
export default ValidationsSpecification;